import React from "react"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import SolidHero from "../../components/solid-hero/solid-hero"
import ContactAndAddressTile from "../../components/contact-and-address-tile/contact-and-address-tile"
import AnmeldungCtaButton from "../../components/anmeldung-cta-button/anmeldung-cta-button"
import ClassOverview from "../../components/class-overview/class-overview"
import ClassOverviewItem from "../../components/class-overview/class-overview-item/class-overview-item"
import AnmeldungSection from "../../components/anmeldung-section/anmeldung-section"

const KlasseAMPage = ({ data }) => (
  <Layout>
    <SEO title="Klasse AM"
         description={"Du willst fahren, worüber andere rappen? Dann mach jetzt den AM-Führerschein bei deiner Fahrschule Rauscher in Backnang – und lass die Roller schreien."}/>
    <SolidHero imageFluid={data.file.childImageSharp.fluid} imageAlt={"Moped-Führerschein Klasse AM"} primary
               title={"Motorradführerschein"} subtitle={() => <>Klasse AM</>}></SolidHero>
    <AnmeldungCtaButton></AnmeldungCtaButton>
    <ClassOverview multiColumn
                   text={"Von Apache bis Straßenbande, Dolce Vita bis Dorfleben – der Roller ist das klassische Wunschzettelobjekt für ein Alter, in dem du eigentlich gar keinen Wunschzettel mehr schreiben willst. Und trotzdem liebäugelt fast jede*r heimlich mit dem Gedanken, sich den abendlichen Fahrtwind ins Gesicht wehen und den ersten großen Schritt in Richtung Unabhängigkeit zur Realität werden zu lassen. Du auch? Dann wird’s Zeit für deinen AM-Führerschein. Neben den eingangs erwähnten (zugegeben ziemlich kitschigen) Faktoren bietet dir der AM-Führerschein besonders innerhalb der Stadt gute Mobilität und ist ein schöner Übergang, zum Beispiel vom Fahrrad. Fahren darfst du mit ihr Zweiräder bis 45 km/h sowie 50 ccm, drei- oder vierrädrige Fahrzeuge mit bis zu zwei Sitzplätzen sowie ebenfalls maximal 45 km/h. Übrigens spielt die Antriebsart dabei keine Rolle: Sowohl Verbrenner als auch Elektro-Fahrzeuge sind mit einer Maximalleistung von 4 kW bemessen, du musst also keine Abstriche machen, wenn du lieber flüsterleise und umweltfreundlich unterwegs bist. Neugierig?"}>
      <ClassOverviewItem title={"Ausbildung"}>
        <li>Mindestalter: 15 Jahre</li>
        <li>In Deutschland kann man den Führerschein ab 15 Jahren machen. Dieser hat dann allerdings nur eine Gültigkeit in Deutschland und nicht im Ausland.</li>
        <li>Ab 16 Jahren auch im Ausland gültig.</li>
        <li>Ausbildung: Theorie und Praxis</li>
        <li>Prüfung: Theorieprüfung und praktische Prüfung</li>
      </ClassOverviewItem>
      <ClassOverviewItem title={"Stundenpensum"}>
        <li>Übungsstunden: nach Bedarf</li>
        <li>Theorie/Doppelstunden á 90 Minuten bei Ersterteilung: 12 (bei Erweiterung nur 6)</li>
        <li>Zusatzstoff: 2</li>
      </ClassOverviewItem>
      <ClassOverviewItem title={"Vorraussetzungen"}>
        <li>Biometrisches Passfoto</li>
        <li>Sehtest</li>
        <li>Erste-Hilfe-Kurs</li>
        <li>Personalausweis oder Reisepass mit Meldebestätigung</li>
      </ClassOverviewItem>
      <ClassOverviewItem title={"Merkmale"}>
        <li>bbH max. 45 km/h</li>
        <li>... Hubraum max. 50 cm³</li>
        <li>... Leistung max. 4 kW bei Diesel-/Elektromotor</li>
      </ClassOverviewItem>
      <ClassOverviewItem title={"Aufstiegs Möglichkeiten"}
                         linkPath={"https://fahrschulerauscher.de/pdf/informationen_klasse_a.pdf"}
                         linkText={"Mehr erfahren"}>
        <li>Motorradführerscheine bieten oft noch Aufstiegsmöglichkeiten zu anderen Klassen. Weiter Infos dazu findest
          du hier.
        </li>
      </ClassOverviewItem>
      <ClassOverviewItem title={"Weitere Infos"} linkPath={"https://tuvsud.com"} linkText={"Zur Website des TÜV"}>
        <li>Weitere Infos zu allen Führerscheinklassen findest du auf der Website des TÜV.</li>
      </ClassOverviewItem>

    </ClassOverview>
    <AnmeldungSection></AnmeldungSection>
    <ContactAndAddressTile></ContactAndAddressTile>
  </Layout>
)

export default KlasseAMPage

export const query = graphql`
  query KlasseAMPageQuery {
    file(relativePath: { eq: "klasse-am.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
